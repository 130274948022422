import { Button } from "primereact/button";
import { Password } from "primereact/password";
import React, { ReactElement, useState } from "react";
import "./ResetPassword.css";
import { useNavigate } from "react-router-dom";
import authService from "../../services/authService";

export default function ResetPassword(): ReactElement {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleReset = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);

    const userEmail = localStorage.getItem("userEmail");

    try {
      await authService.resetPassword(newPassword, userEmail);
      navigate("/");
      setLoading(false);
    } catch (err) {
      setError("Erro ao mudar senha. Entre em contato com o suporte!");
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <img src="./thumb-logo-sem_slogan.png" alt="Logo" className="logo" />
        <form onSubmit={handleReset}>
          <div className="p-field">
            <label htmlFor="email">Nova Senha</label>
            <Password
              id="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="p-field">
            <label htmlFor="password">Confirmação Nova Senha</label>
            <Password
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              feedback={false}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <Button
            label="Registrar"
            type="submit"
            className="p-button-primary"
            loading={loading}
            disabled={newPassword.length < 6 || confirmPassword !== newPassword}
          />
        </form>
      </div>
    </div>
  );
}
