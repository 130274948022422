import React, {
  FormEvent,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button } from "primereact/button";
import LevelInput from "../level-input/LevelInput";
import EanInput from "../ean-input/EanInput";
import { CategoryParams } from "../../utils/types";
import { Toast } from "primereact/toast";
import categoriesService from "../../services/categoriesService";
import SessionInfo from "../session-info/SessionInfo";
import { Chips } from "primereact/chips";

interface FormCategoriaProps {
  dataChange: (value: any) => void;
  setExclude: (value: any) => void;
  isLoading: (value: boolean) => void;
  totalChange: (value: number) => void;
  sessionIdChange: (value: number) => void;
  sessionChange: (value: boolean) => void;
  done: number;
  loading: boolean;
  session: boolean;
  exclude: any;
  mode: "REMOVE" | "INCLUDE";
  rules?: string[];
  setRules?: (value: string[]) => void;
  listRules?: any[];
  setListRules?: (value: any[]) => void;
}

interface SessionDataObj {
  id: number;
  total: number;
  init: string;
  type: "REMOVE" | "INCLUDE" | "";
}

const FormCategoria: React.FC<FormCategoriaProps> = ({
  dataChange,
  isLoading,
  loading,
  done,
  totalChange,
  sessionIdChange,
  session,
  sessionChange,
  mode,
  setExclude,
  exclude,
  rules = [],
  setRules,
  listRules = [],
  setListRules,
}) => {
  const [eanValue, setEanValue] = useState<string>("");
  const [levelValues, setLevelValues] = useState<string[]>(Array(7).fill(""));
  const [sessionObj, setSessionObj] = useState<SessionDataObj>({
    id: 0,
    init: "",
    total: 0,
    type: "",
  });
  const [marcas, setMarcas] = useState<any>([]);
  const toast = useRef<Toast>(null);

  const handleEanChange = (value: string): void => {
    setEanValue(value);
  };

  const handleLevelChange = (values: string[]): void => {
    setLevelValues(values);
  };

  const getUserSession = async (): Promise<void> => {
    isLoading(true);
    const email = localStorage.getItem("userEmail");
    const sessionData = await categoriesService.getUserSession(email, mode);
    if (sessionData) {
      dataChange(sessionData.data);
      setExclude(sessionData.exclude);
      if (setRules) setRules(sessionData.rules);
      if (setListRules) setListRules(sessionData.listRules);
      totalChange(sessionData.total);
      sessionIdChange(sessionData.id);
      setSessionObj({
        id: sessionData.id,
        total: sessionData.total,
        init: sessionData.created_at,
        type: mode,
      });
      sessionChange(true);
    } else {
      sessionChange(false);
      isLoading(false);
    }
  };

  useEffect(() => {
    getUserSession();
  }, []);

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault();
    isLoading(true);
    let emptyCount = 0;
    for (let i = 0; i < levelValues.length; i++) {
      if (levelValues[i] === "") {
        emptyCount++;
      }
    }
    if (emptyCount > 3 && eanValue.length < 4) {
      toast.current?.show({
        severity: "info",
        summary: "Info",
        detail: "Preencha mais informações para a busca!",
      });
      isLoading(false);
      return;
    }

    const dataParam: CategoryParams = {};

    if (eanValue.length > 0) dataParam["ean"] = eanValue;
    if (levelValues[0] !== "") dataParam["n1"] = parseInt(levelValues[0]);
    if (levelValues[1] !== "") dataParam["n2"] = parseInt(levelValues[1]);
    if (levelValues[2] !== "") dataParam["n3"] = parseInt(levelValues[2]);
    if (levelValues[3] !== "") dataParam["n4"] = parseInt(levelValues[3]);
    if (levelValues[4] !== "") dataParam["n5"] = parseInt(levelValues[4]);
    if (levelValues[5] !== "") dataParam["n6"] = parseInt(levelValues[5]);
    if (levelValues[6] !== "") dataParam["n7"] = parseInt(levelValues[6]);
    if (marcas.length > 0) dataParam["marcas"] = marcas;

    if (session) {
      let request;
      if (mode === "REMOVE") {
        request = await categoriesService.getDataByFilter(dataParam);
      } else {
        request = await categoriesService.getDataByFilterToInclude(dataParam);
      }
      dataChange(request);
      await categoriesService.updateSession(
        sessionObj.id,
        request,
        exclude,
        rules,
        listRules
      );
    } else {
      const response = await categoriesService.createSession(dataParam, mode);
      dataChange(response.data);
      setExclude([]);
      totalChange(response.total);
      sessionIdChange(response.id);
      setSessionObj({
        id: response.id,
        total: response.total,
        init: response.created_at,
        type: mode,
      });
      sessionChange(true);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "20px",
      }}
    >
      {mode === "REMOVE" ? (
        <h1>Remoção de Itens</h1>
      ) : (
        <h1>Inclusão de Itens</h1>
      )}
      <LevelInput onChange={handleLevelChange} />
      <EanInput onChange={handleEanChange} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "5px",
        }}
      >
        <label>Marcas:</label>
        <Chips
          value={marcas}
          onChange={(e) => setMarcas(e.value)}
          style={{ width: "310px", height: "40px", textAlign: "start" }}
        />
      </div>
      <Button
        style={{
          width: "310px",
          height: "40px",
          backgroundColor: "#0075b3",
          borderColor: "#0075b3",
        }}
        type="submit"
        label="Pesquisar"
        loading={loading}
      />
      {session ? (
        <SessionInfo
          inicio={sessionObj.init}
          totalItens={sessionObj.total}
          itensFeitos={done}
        />
      ) : (
        <></>
      )}
      <Toast ref={toast} />
    </form>
  );
};

export default FormCategoria;
