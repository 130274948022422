import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";


const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT
})

axiosInstance.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		const token = localStorage.getItem('accessToken')
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`
		}

		return config
	},
	(error: AxiosError) => {
		return Promise.reject(error)
	}
)

axiosInstance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response
	},
	async (error: AxiosError) => {
		const originalRequest = error.config as InternalAxiosRequestConfig & { _retry: boolean }
		if (error.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			try {
				const refreshToken = localStorage.getItem('refreshToken');
				const { data } = await axios.post(`${axiosInstance.defaults.baseURL}/api/auth/refresh`, { refreshToken });
				localStorage.setItem('accessToken', data.accessToken);
				originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
				return axiosInstance(originalRequest);
			} catch (refreshError) {
				return Promise.reject(refreshError);
			}
		}
		return Promise.reject(error);

	}
)

export default axiosInstance