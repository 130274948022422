import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import React, { ReactElement, useState } from "react";
import "./LoginPage.css";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";

interface LoginProps {
  auth: () => void
}

export default function LoginPage({ auth }: LoginProps): ReactElement {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    try {
      const userData = await authService.login(email, password)
      auth()
      navigate('/')
      setLoading(false)
    } catch (err) {
      setError("Erro ao fazer login. Verifique suas credenciais.");
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <img src="./thumb-logo-sem_slogan.png" alt="Logo" className="logo" />
        <form onSubmit={handleLogin}>
          <div className="p-field">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="p-field">
            <label htmlFor="password">Senha</label>
            <Password
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              feedback={false}
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <Button
            label="Login"
            type="submit"
            className="p-button-primary"
            loading={loading}
          />
        </form>
      </div>
    </div>
  );
}
