import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Divider } from "primereact/divider";
import FormCategoria from "../../components/form-categoria/FormCategoria";
import {
  IncludeList,
  ItemToInclude,
  RemoveItems,
  Suggestion,
} from "../../utils/types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import InfoBar from "../../components/info-bar/InfoBar";
import categoriesService from "../../services/categoriesService";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";

export default function DeparaInclude(): ReactElement {
  const [tableData, setTableData] = useState<IncludeList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<Suggestion[]>([]);
  const [originalData, setOriginalData] = useState<Suggestion[]>([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogHeader, setDialogHeader] = useState<string>("");
  const [dialogEan, setDialogEan] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [exclude, setExclude] = useState<any>([]);
  const [removeEnable, setRemoveEnable] = useState<boolean>(false);
  const [rules, setRules] = useState<string[]>([]);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const [session, setSession] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [ruleFlag, setRuleFlag] = useState<boolean>(false);
  const [listRuleFlag, setListRuleFlag] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [startInput, setStartInput] = useState<string>("");
  const [inlcudeInput1, setIncludeInput1] = useState<string>("");
  const [inlcudeInput2, setIncludeInput2] = useState<string>("");
  const [inlcudeInput3, setIncludeInput3] = useState<string>("");
  const [totalDialog, setTotalDialog] = useState<number>(0);
  const [filter1, setFIlter1] = useState<number>(0);
  const [filter2, setFilter2] = useState<number>(0);
  const [filter3, setFilter3] = useState<number>(0);
  const [listRules, setListRules] = useState<any[]>([]);
  const toast = useRef<Toast>(null);

  const loadingChange = (value: boolean): void => {
    setLoading(value);
  };

  const totalChange = (value: number): void => {
    setTotal(value);
  };

  const sessionChange = (value: boolean): void => {
    setSession(value);
  };

  const dataChange = (value: IncludeList[]): void => {
    setTableData(value);
    setLoading(false);
  };

  const getDataByEan = async (ean: string): Promise<any> => {
    const data = await categoriesService.getSuggestions(ean);
    if (data) {
      const filteredData = data.filter(
        (item: any) =>
          !exclude.some(
            (excludeItem: any) =>
              excludeItem.name === item.desc_prod_estab &&
              excludeItem.cnpj === item.cnpj
          ) &&
          !rules.includes(item.desc_prod_estab) &&
          !listRules.some(
            (ruleItem: any) =>
              ruleItem.desc_prod_estab === item.desc_prod_estab &&
              ruleItem.cnpj === item.cnpj &&
              ruleItem.ean === dialogEan
          )
      );
      setFIlter1(0);
      setFilter2(0);
      setFilter3(0);
      setDialogData(filteredData);
      setOriginalData(filteredData);
      setTotalDialog(filteredData.length);
    } else {
      setDialogData([]);
      setOriginalData([]);
      setTotalDialog(0);
    }
    setDialogLoading(false);
  };

  const handleDialogOpen = (ean: string): void => {
    setDialogLoading(true);
    setDialogVisible(true);
    getDataByEan(ean);
  };

  const sessionIdChange = (value: number): void => {
    setSessionId(value);
  };

  const updateSession = (data: IncludeList[]): void => {
    try {
      categoriesService.updateSession(
        sessionId,
        data,
        exclude,
        rules,
        listRules
      );
    } catch (e) {
      console.error(e);
    }
  };

  const removeSession = (): void => {
    setLoading(true);
    try {
      categoriesService.removeAsync(sessionId).then((data) => {
        if (data.data === "Sessão removida") {
          toast.current?.show({
            severity: "info",
            summary: "Info",
            detail:
              "Processando modificações, você será notificado quando o processo for finalizado!",
          });
        } else {
          toast.current?.show({
            severity: "error",
            summary: "Erro",
            detail:
              "Ocorreu um erro ao finalizar a sessão, entre em contato com o suporte!",
          });
        }
      });
    } catch (e) {
      console.error(e);
      toast.current?.show({
        severity: "error",
        summary: "Erro",
        detail:
          "Ocorreu um erro ao finalizar a sessão, entre em contato com o suporte!",
      });
    } finally {
      setLoading(false);
    }
  };

  const removeData = async (): Promise<void> => {
    setRemoveLoading(true);
    const items = [...exclude];
    setFIlter1(filter1 + selectedItems.length);
    for (let i = 0; i < selectedItems.length; i++) {
      items.push({
        ean: dialogEan,
        name: selectedItems[i].desc_prod_estab,
        cnpj: selectedItems[i].cnpj,
        code: selectedItems[i].cod_prod_estab,
      });
    }
    setExclude(items);
    try {
      const response = await categoriesService.updateSession(
        sessionId,
        dialogData,
        items,
        rules,
        listRules
      );
      if (response !== "Nenhuma linha foi afetada") {
        const newData = dialogData.filter(
          (item) =>
            !items.some((toRemove) => toRemove.name === item.desc_prod_estab)
        );
        const newTableData = tableData.map((item) => {
          if (item.ean === dialogEan) {
            return { ...item, data: newData };
          }
          return item;
        });

        setDialogData(newData);
        setOriginalData(newData);
        setTableData(newTableData);
        updateSession(newTableData);
        setSelectedItems([]);
      }
    } catch (error) {
      console.error("Erro ao remover dados", error);
    } finally {
      setRemoveLoading(false);
    }
  };

  useEffect(() => {
    if (selectedItems.length === 0) {
      setRemoveEnable(false);
    } else {
      setRemoveEnable(true);
    }
  }, [selectedItems]);

  const checkDone = (data: IncludeList): void => {
    setTableLoading(true);
    const newData = tableData.filter((item) => item.ean !== data.ean);
    if (newData.length > 0) {
      updateSession(newData);
    } else {
      removeSession();
      setSession(false);
    }
    setTableData(newData);
    setTableLoading(false);
  };

  const actionsBody = (data: IncludeList): ReactElement => {
    return (
      <div>
        <Button
          icon="pi pi-search"
          title="Acessar este EAN"
          onClick={() => {
            setDialogHeader(data.name);
            setDialogEan(data.ean);
            handleDialogOpen(data.ean);
          }}
        />
        <Button
          style={{ marginLeft: "5px" }}
          icon="pi pi-check"
          title="Marcar como feito"
          onClick={() => {
            checkDone(data);
          }}
        />
      </div>
    );
  };

  const handleEqualsSelect = (desc: string): void => {
    const similar = dialogData.filter((i) => i.desc_prod_estab === desc);
    setSelectedItems((prevSelected: any) => [
      ...new Set([...prevSelected, ...similar]),
    ]);
  };

  const dialogActionColumn = (data: Suggestion): ReactElement => {
    return (
      <Button
        icon="pi pi-wrench"
        size="small"
        tooltip="Seleciona todos os itens com a mesma descrição"
        tooltipOptions={{ position: "bottom" }}
        style={{}}
        onClick={() => handleEqualsSelect(data.desc_prod_estab)}
      />
    );
  };

  const pointsColorTemplate = (rowData: Suggestion): ReactElement => {
    const score = Number((parseFloat(rowData.score) * 100).toFixed(2));
    const color = score < 50 ? "red" : "green";
    return <span style={{ color: color }}>{score}</span>;
  };

  const dateTemplate = (rowData: Suggestion): ReactElement => {
    const timestamp = parseFloat(rowData.data_emissao) * 1000;
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return <span>{formattedDate}</span>;
  };

  const removeFromList = (): void => {
    setRemoveLoading(true);
    const regras = [...listRules];
    setFilter2(filter2 + selectedItems.length);
    selectedItems.forEach((item: Suggestion) => {
      const itemComEan = {
        ...item,
        ean: dialogEan,
      };
      if (
        !regras.some(
          (regra: any) =>
            regra.ean === itemComEan.ean &&
            regra.cnpj === itemComEan.cnpj &&
            regra.desc_prod_estab === itemComEan.desc_prod_estab
        )
      ) {
        regras.push(itemComEan);
      }
    });
    const filteredItems = dialogData.filter(
      (item) => !selectedItems.includes(item)
    );
    const newOriginal = originalData.filter(
      (item) => !selectedItems.includes(item)
    );
    setDialogData(filteredItems);
    setOriginalData(newOriginal);
    setListRules(regras);
    setSelectedItems([]);
    updateSession(tableData);
    setRemoveLoading(false);
  };

  const removeFromAllLists = (): void => {
    setRemoveLoading(true);

    const regras = [...rules];
    selectedItems.forEach((item: Suggestion) => {
      if (!regras.includes(item.desc_prod_estab)) {
        regras.push(item.desc_prod_estab);
      }
    });
    const qtd = originalData.filter((item) =>
      regras.includes(item.desc_prod_estab)
    ).length;
    setFilter3(filter3 + qtd);

    const filteredItems = dialogData.filter(
      (item) => !regras.includes(item.desc_prod_estab)
    );
    const newOriginal = originalData.filter(
      (item) => !regras.includes(item.desc_prod_estab)
    );
    setDialogData(filteredItems);
    setOriginalData(newOriginal);

    setRules(regras);
    setSelectedItems([]);
    updateSession(tableData);
    setRemoveLoading(false);
  };

  const filterData = (): void => {
    setRemoveLoading(true);
    setDialogData(
      originalData.filter((item: any) => {
        const descricao = item.desc_prod_estab.toUpperCase();

        const filterByInput1 = startInput
          ? descricao.startsWith(startInput.toUpperCase())
          : true;
        const filterByInput2 = inlcudeInput1
          ? descricao.includes(inlcudeInput1.toUpperCase())
          : true;
        const filterByInput3 = inlcudeInput2
          ? descricao.includes(inlcudeInput2.toUpperCase())
          : true;
        const filterByInput4 = inlcudeInput3
          ? descricao === inlcudeInput3.toUpperCase()
          : true;

        return (
          filterByInput1 && filterByInput2 && filterByInput3 && filterByInput4
        );
      })
    );
    setRemoveLoading(false);
  };

  const clearFilter = (): void => {
    setDialogData(originalData);
    setStartInput("");
    setIncludeInput1("");
    setIncludeInput2("");
  };

  const handleExcludeChange = (value: any): void => {
    if (value) {
      setExclude(value);
    } else {
      setExclude([]);
    }
  };

  const toolbarEnd = (
    <React.Fragment>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Button
          icon="pi pi-check-circle"
          severity="success"
          tooltip="Associar"
          tooltipOptions={{ position: "bottom" }}
          onClick={removeData}
        />
        <Button
          icon="pi pi-eraser"
          severity="warning"
          tooltip="Remover desta lista"
          tooltipOptions={{ position: "bottom" }}
          onClick={removeFromList}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          tooltip="Remover de todas as listas"
          tooltipOptions={{ position: "bottom" }}
          onClick={removeFromAllLists}
        />
      </div>
    </React.Fragment>
  );

  const toolbarStart = (
    <React.Fragment>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <InputText
          style={{ height: "36.5px", width: "150px" }}
          placeholder="Começa com"
          value={startInput}
          onChange={(e) => setStartInput(e.target.value)}
        />
        <InputText
          style={{ height: "36.5px", width: "150px" }}
          placeholder="Contém"
          value={inlcudeInput1}
          onChange={(e) => setIncludeInput1(e.target.value)}
        />
        <InputText
          style={{ height: "36.5px", width: "150px" }}
          placeholder="Contém"
          value={inlcudeInput2}
          onChange={(e) => setIncludeInput2(e.target.value)}
        />
        <InputText
          style={{ height: "36.5px", width: "150px" }}
          placeholder="Igual a"
          value={inlcudeInput3}
          onChange={(e) => setIncludeInput3(e.target.value)}
        />
        <Button icon="pi pi-search" onClick={filterData} />
        <Button label="Limpar" text onClick={clearFilter} />
      </div>
    </React.Fragment>
  );

  const removeRule = (ruleToRemove: string): void => {
    setRules((prevRules) => prevRules.filter((rule) => rule !== ruleToRemove));
  };

  useEffect(() => {
    if (!ruleFlag) {
      updateSession(tableData);
    }
    setRuleFlag(false);
  }, [rules]);

  useEffect(() => {
    if (listRuleFlag) {
      updateSession(tableData);
    }
    setListRuleFlag(true);
  }, [listRules]);

  const handleRulesChange = (value: string[]): void => {
    setRuleFlag(true);
    setRules(value);
  };

  const handleListRulesChange = (value: any[]): void => {
    setListRuleFlag(true);
    setListRules(value);
  };

  const footer = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <span>
        <span
          style={{
            backgroundColor: "green",
            borderRadius: "50%",
            width: "10px",
            height: "10px",
            display: "inline-block",
            marginRight: "5px",
          }}
        />
        {filter1}
      </span>
      <span>
        <span
          style={{
            backgroundColor: "yellow",
            borderRadius: "50%",
            width: "10px",
            height: "10px",
            display: "inline-block",
            marginRight: "5px",
          }}
        />
        {filter2}
      </span>
      <span>
        <span
          style={{
            backgroundColor: "red",
            borderRadius: "50%",
            width: "10px",
            height: "10px",
            display: "inline-block",
            marginRight: "5px",
          }}
        />
        {filter3}
      </span>
      <span style={{ marginLeft: "5px" }}>
        Itens feitos: {filter1 + filter2 + filter3}
      </span>
      <span style={{ marginLeft: "5px" }}>Total de itens: {totalDialog}</span>
    </div>
  );

  return (
    <>
      <Toast ref={toast} />
      <InfoBar />
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "30px",
          height: "85vh",
          marginTop: "5vh",
          marginLeft: "5vw",
          width: "90vw",
        }}
      >
        <FormCategoria
          dataChange={dataChange}
          isLoading={loadingChange}
          loading={loading}
          totalChange={totalChange}
          done={total - tableData.length}
          sessionIdChange={sessionIdChange}
          session={session}
          sessionChange={sessionChange}
          mode="INCLUDE"
          setExclude={handleExcludeChange}
          exclude={exclude}
          rules={rules}
          setRules={handleRulesChange}
          listRules={listRules}
          setListRules={handleListRulesChange}
        />
        <Divider layout="vertical" />
        {loading ? (
          <ProgressSpinner />
        ) : tableData.length > 0 ? (
          <div>
            {rules.length > 0 && (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginBottom: "20px",
                  flexWrap: "wrap",
                  maxHeight: "5vh",
                  overflow: "auto",
                }}
              >
                {rules.map((rule) => (
                  <Chip
                    key={rule}
                    label={rule}
                    removable
                    onRemove={() => removeRule(rule)}
                  />
                ))}
              </div>
            )}
            <DataTable
              value={tableData}
              tableStyle={{ width: "70vw" }}
              scrollable
              stripedRows
              sortField="qtd"
              sortOrder={-1}
              scrollHeight="75vh"
              loading={tableLoading}
              paginator
              rows={20}
            >
              <Column field="ean" header="EAN" sortable />
              <Column field="name" header="Descrição" sortable />
              <Column field="qtd" header="Tx" sortable />
              <Column
                header="Ações"
                body={actionsBody}
                style={{ width: "10%" }}
              ></Column>
            </DataTable>
          </div>
        ) : (
          <></>
        )}
        <Dialog
          visible={dialogVisible}
          onHide={() => setDialogVisible(false)}
          header={dialogHeader}
          resizable={false}
          draggable={false}
          style={{ width: "95vw", height: "95vh" }}
        >
          {dialogLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <ProgressSpinner />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Toolbar
                end={toolbarEnd}
                start={toolbarStart}
                style={{ width: "100%" }}
              />
              <DataTable
                value={dialogData}
                scrollable
                stripedRows
                scrollHeight="70vh"
                selection={selectedItems}
                onSelectionChange={(e) => setSelectedItems(e.value)}
                style={{ width: "100%" }}
                showSelectAll={false}
                loading={removeLoading}
                sortMode="multiple"
                virtualScrollerOptions={{ itemSize: 50 }}
                size="small"
                dragSelection
                footer={footer}
              >
                <Column selectionMode="multiple"></Column>
                <Column
                  field="desc_prod_estab"
                  header="Descrição"
                  sortable
                ></Column>
                <Column field="cnpj_raiz" header="CNPJ Raiz" sortable></Column>
                <Column
                  field="ce_nivel_3"
                  header="T. Estabelecimento"
                  sortable
                ></Column>
                <Column
                  field="score"
                  header="Chance de acerto"
                  body={pointsColorTemplate}
                  sortable
                ></Column>
                <Column
                  field="item_valor_unidade"
                  header="Último valor"
                  sortable
                ></Column>
                <Column
                  field="data_emissao"
                  header="Data"
                  sortable
                  body={dateTemplate}
                ></Column>
                <Column
                  header="Ações"
                  body={dialogActionColumn}
                  style={{ width: "10%" }}
                ></Column>
              </DataTable>
            </div>
          )}
        </Dialog>
      </div>
    </>
  );
}
