import { InputText } from "primereact/inputtext";
import React, { useState } from "react";

interface LevelInputProps {
  onChange: (values: string[]) => void;
}

const LevelInput: React.FC<LevelInputProps> = ({ onChange }) => {
  const [values, setValues] = useState(Array(7).fill(""));

  const handleChange = (index: any, event: any): void => {
    const newValues = [...values];
    newValues[index] = event.target.value;
    setValues(newValues);
    onChange(newValues);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "5px",
      }}
    >
      <label>Níveis:</label>
      <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
        {values.map((value, index) => (
          <InputText
            key={index}
            value={value}
            keyfilter="pint"
            onChange={(e) => handleChange(index, e)}
            placeholder={`N${index + 1}`}
            style={{ width: "40px", height: "40px", textAlign: "center" }}
          />
        ))}
      </div>
    </div>
  );
};

export default LevelInput;
