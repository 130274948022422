import { Button } from "primereact/button"
import { Menubar } from "primereact/menubar"
import { MenuItem } from "primereact/menuitem"
import React, { ReactElement } from "react"
import { useNavigate } from "react-router-dom"

export default function InfoBar(): ReactElement {
	const navigate = useNavigate()

	const logOut = (): void => {
		localStorage.removeItem('accessToken')
		localStorage.removeItem('refreshToken')
		navigate('/login')
	}

	const start = <img alt="logo" src="./thumb-v360-logo.png" style={{height: "50px", marginRight: "10px"}} />
	const model: MenuItem[] = [
		{
			label: 'Remover Itens',
			icon: 'pi pi-minus-circle',
			command: () => {
				const route = window.location.pathname
				if(route === '/') {
					return
				} else {
					navigate('/')
				}
			}
		},
		{
			label: 'Incluir Itens',
			icon: 'pi pi-plus-circle',
			command: () => {
				const route = window.location.pathname
				if(route === '/include') {
					return
				} else {
					navigate('/include')
				}
			}
		}
	]
	const end = (
		<Button
			label="Sair"
			icon="pi pi-sign-out"
			onClick={() => logOut()}
			style={{
				backgroundColor: '#0075b3',
				borderColor: '#0075b3'
			}}
		/>
	)

	return (
		<Menubar start={start} model={model} end={end} />
	)

}