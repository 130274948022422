import { AxiosResponse } from "axios";
import {
  CategoryData,
  CategoryParams,
  IncludeList,
  RemoveItems,
  SessionData,
  Suggestion,
} from "../utils/types";
import axiosInstance from "../utils/axiosInstance";

const getDataByFilter = async (
  params: CategoryParams
): Promise<CategoryData[]> => {
  try {
    const response: AxiosResponse<CategoryData[]> = await axiosInstance.post(
      `/categories/get-data`,
      params
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter dados de categorias", error);
    throw error;
  }
};

const getDataByFilterToInclude = async (
  params: CategoryParams
): Promise<IncludeList[]> => {
  try {
    const response: AxiosResponse<IncludeList[]> = await axiosInstance.post(
      `/categories/get-include-list`,
      params
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter dados de categorias", error);
    throw error;
  }
};

const getSuggestions = async (ean: string): Promise<Suggestion[]> => {
  try {
    const response: AxiosResponse<Suggestion[]> = await axiosInstance.get(
      `/categories/suggestions/${ean}`
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao obter sugestões para o EAN", error);
    throw error;
  }
};

const removeData = async (items: RemoveItems): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axiosInstance.post(
      "/categories/remove-data",
      items
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao remover itens", error);
    throw error;
  }
};

const getUserSession = async (
  email: any,
  type: "REMOVE" | "INCLUDE"
): Promise<SessionData> => {
  try {
    const response: AxiosResponse<SessionData> = await axiosInstance.post(
      "/categories/get-user-sessions",
      { email, type }
    );
    return response.data;
  } catch (error) {
    console.error("Erro ao capturar sessões de usuário", error);
    throw error;
  }
};

const createSession = async (
  params: CategoryParams,
  type: "REMOVE" | "INCLUDE"
): Promise<SessionData> => {
  const data = {
    email: localStorage.getItem("userEmail"),
    params: params,
    type,
  };
  const response = await axiosInstance.post("/categories/create-session", data);
  return response.data;
};

const updateSession = async (
  id: number,
  data: any,
  exclude: any,
  rules: any,
  listRules: any
): Promise<any> => {
  const dataString = JSON.stringify(data);
  const excludeString = JSON.stringify(exclude);
  const rulesString = JSON.stringify(rules);
  const listRulesString = JSON.stringify(listRules);
  const request = await axiosInstance.post("/categories/update-session", {
    id,
    data: dataString,
    exclude: excludeString,
    rules: rulesString,
    list_rules: listRulesString,
  });
  return request;
};

const removeSession = async (id: number): Promise<any> => {
  const request = await axiosInstance.post("/categories/remove-session", {
    id,
  });
  return request;
};

const removeAsync = async (id: number): Promise<any> => {
  const email = localStorage.getItem("userEmail");
  const request = await axiosInstance.post("/categories/remove-async", {
    id,
    email,
  });
  return request;
};

export default {
  getDataByFilter,
  removeData,
  getUserSession,
  createSession,
  updateSession,
  removeSession,
  getDataByFilterToInclude,
  getSuggestions,
  removeAsync,
};
