import React from "react";
import './SessionInfo.css'
import moment from "moment";

interface SessaoAtivaProps {
  inicio: string;
  totalItens: number;
  itensFeitos: number;
}

const SessionInfo: React.FC<SessaoAtivaProps> = ({
  inicio,
  totalItens,
  itensFeitos,
}) => {
  const formattedDate = moment(inicio, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD/MM/YYYY')
  const conclusao = ((itensFeitos / totalItens) * 100).toFixed(1);

  return (
    <div className="container">
      <h3>Sessão Ativa</h3>
      <p>Início: {formattedDate}</p>
      <p>Total de itens: {totalItens}</p>
      <p>Itens feitos: {itensFeitos}</p>
      <p>Conclusão: {conclusao}%</p>
    </div>
  );
};
 
export default SessionInfo