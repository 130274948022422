/* eslint-disable no-useless-catch */
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";

interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  reset: boolean;
  email: string;
}

const login = async (
  email: string,
  password: string
): Promise<AuthResponse> => {
  try {
    const { data } = await axiosInstance.post<AuthResponse>("/api/auth/login", {
      email,
      password,
    });
    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("refreshToken", data.refreshToken);
    localStorage.setItem("userEmail", data.email);
    return data;
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (password: string, email: any): Promise<any> => {
  try {
    const response = await axiosInstance.post("/api/users/new-psw", {
      password,
      email,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const logout = (): void => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

const refreshToken = async (): Promise<AuthResponse> => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const { data } = await axiosInstance.post<AuthResponse>(
      "/api/auth/refresh",
      { refreshToken }
    );
    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("refreshToken", data.accessToken);
    return data;
  } catch (error) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.replace("/login");
    throw error;
  }
};

export default { login, logout, refreshToken, resetPassword };
