import { InputText } from "primereact/inputtext";
import React, { ChangeEvent, useState } from "react";

interface EanInputProps {
  onChange: (value: string) => void;
}

const EanInput: React.FC<EanInputProps> = ({ onChange }) => {
  const [value, setValue] = useState<string>("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "5px",
      }}
    >
      <label>EAN: </label>
      <InputText
        placeholder="EAN-13"
        value={value}
        onChange={(e) => handleChange(e)}
        maxLength={13}
        style={{ width: "310px", height: "40px", textAlign: "start" }}
      />
    </div>
  );
};

export default EanInput;
