import React, { useEffect, useState } from 'react';
import './App.css';
import DeparaRemove from './pages/depara-remove/DeparaRemove';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { PrimeReactProvider, addLocale} from 'primereact/api';
import ptBR from './pt-br.json'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login/LoginPage';
import ProtectedRoute from './ProtectedRoute';
import { ProgressSpinner } from 'primereact/progressspinner';
import ResetPassword from './pages/reset-password/ResetPassword';
import DeparaInclude from './pages/depara-include/DeparaInclude';

addLocale('pt-br', ptBR)

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken')
    if(accessToken) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
    setLoading(false)
  }, [])

  const handleAuthChange = (): void => {
    setIsAuthenticated(true)
  }

  if(loading) {
    return(
      <div style={{width: '95vw', height: '95vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <ProgressSpinner />  
      </div>
    )
  }
  
  const value = {
    locale: 'pt-br'
  }
  return (
    <PrimeReactProvider value={value}>
      <Router>
        <Routes>
          <Route path='/login' element={<LoginPage auth={handleAuthChange} />} />
          <Route path='/reset' element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <ResetPassword />
            </ProtectedRoute>
          } />
          <Route path='/' element={
            <ProtectedRoute isAuthenticated={isAuthenticated} >
              <DeparaRemove />
            </ProtectedRoute>
          } />
          <Route path='/include' element={
            <ProtectedRoute isAuthenticated={isAuthenticated} >
              <DeparaInclude />
            </ProtectedRoute>
          } />
        </Routes>
      </Router>
    </PrimeReactProvider>
  );

}

export default App;
