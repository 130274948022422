/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useEffect, useState } from "react";
import { Divider } from "primereact/divider";
import FormCategoria from "../../components/form-categoria/FormCategoria";
import {
  CategoryData,
  DetailedData,
  ItemToRemove,
  RemoveItems,
} from "../../utils/types";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import InfoBar from "../../components/info-bar/InfoBar";
import categoriesService from "../../services/categoriesService";

export default function DeparaRemove(): ReactElement {
  const [tableData, setTableData] = useState<CategoryData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<DetailedData[]>([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogHeader, setDialogHeader] = useState<string>("");
  const [dialogEan, setDialogEan] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [removeEnable, setRemoveEnable] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const [session, setSession] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  const loadingChange = (value: boolean): void => {
    setLoading(value);
  };

  const totalChange = (value: number): void => {
    setTotal(value);
  };

  const sessionChange = (value: boolean): void => {
    setSession(value);
  };

  const dataChange = (value: CategoryData[]): void => {
    setTableData(value);
    setLoading(false);
  };

  const handleDialogOpen = (data: DetailedData[]): void => {
    setDialogData(data);
    setDialogVisible(true);
  };

  const sessionIdChange = (value: number): void => {
    setSessionId(value);
  };

  const updateSession = (data: CategoryData[]): void => {
    try {
      categoriesService.updateSession(sessionId, data, [], [], []);
    } catch (e) {
      console.error(e);
    }
  };

  const removeSession = (): void => {
    try {
      categoriesService.removeSession(sessionId);
    } catch (e) {
      console.error(e);
    }
  };

  const removeData = async (): Promise<void> => {
    setRemoveLoading(true);
    const items: ItemToRemove[] = [];
    for (let i = 0; i < selectedItems.length; i++) {
      items.push({
        ean: dialogEan,
        name: selectedItems[i].desc_prod_estab,
      });
    }
    const userEmail = localStorage.getItem("userEmail");
    const dataToSend: RemoveItems = {
      items: items,
      email: userEmail,
    };

    try {
      const response = await categoriesService.removeData(dataToSend);

      if (response !== "Nenhuma linha foi afetada") {
        const newData = dialogData.filter(
          (item) =>
            !items.some((toRemove) => toRemove.name === item.desc_prod_estab)
        );
        const newTableData = tableData.map((item) => {
          if (item.ean === dialogEan) {
            return { ...item, data: newData };
          }
          return item;
        });

        setDialogData(newData);
        setTableData(newTableData);
        updateSession(newTableData);
        setSelectedItems([]);
      }
    } catch (error) {
      console.error("Erro ao remover dados", error);
    } finally {
      setRemoveLoading(false);
    }
  };

  useEffect(() => {
    if (selectedItems.length === 0) {
      setRemoveEnable(false);
    } else {
      setRemoveEnable(true);
    }
  }, [selectedItems]);

  const checkDone = (data: CategoryData): void => {
    setTableLoading(true);
    const newData = tableData.filter((item) => item.ean !== data.ean);
    if (newData.length > 0) {
      updateSession(newData);
    } else {
      removeSession();
      setSession(false);
    }
    setTableData(newData);
    setTableLoading(false);
  };

  const actionsBody = (data: CategoryData): ReactElement => {
    return (
      <div>
        <Button
          icon="pi pi-search"
          title="Acessar este EAN"
          onClick={() => {
            setDialogHeader(data.name);
            setDialogEan(data.ean);
            handleDialogOpen(data.data);
          }}
        />
        <Button
          style={{ marginLeft: "5px" }}
          icon="pi pi-check"
          title="Marcar como feito"
          onClick={() => {
            checkDone(data);
          }}
        />
      </div>
    );
  };

  const pointsColorTemplate = (rowData: DetailedData): ReactElement => {
    const score = parseFloat(rowData.points);
    const color = score < 50 ? "red" : "green";
    return <span style={{ color: color }}>{score}</span>;
  };

	return (
		<>
			<InfoBar />
			<div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '30px', height: '85vh', marginTop: '5vh', marginLeft: '5vw', width: '90vw' }}>
				<FormCategoria dataChange={dataChange} isLoading={loadingChange} loading={loading} totalChange={totalChange} done={total- tableData.length} sessionIdChange={sessionIdChange} session={session} sessionChange={sessionChange} mode='REMOVE' setExclude={(value: any) => {} } exclude={[]} />
				<Divider layout="vertical" />
				{(loading ? <ProgressSpinner /> :
					(tableData.length > 0 ?
						<DataTable
							value={tableData}
							tableStyle={{ width: '70vw' }}
							scrollable
							stripedRows
							sortField='min'
							sortOrder={1}
							scrollHeight='80vh'
							loading={tableLoading}
							paginator
							rows={20}
						>
							<Column field='ean' header='EAN' filter filterPlaceholder='EAN' sortable />
							<Column field='name' header='Descrição' filter filterPlaceholder='Descrição' sortable />
							<Column field='min' header='N. Min' sortable />
							<Column field='avg' header='N. Med' sortable />
							<Column field='max' header='N. Max' sortable />
							<Column header='Ações' body={actionsBody} style={{width:'10%'}} ></Column>
						</DataTable> : <></>)
				)}
				<Dialog
					visible={dialogVisible}
					onHide={() => setDialogVisible(false)}
					header={dialogHeader}
					resizable={false}
					draggable={false}
					style={{ width: '95vw', height: '95vh' }}
				>
					<div style={{display:"flex",  flexDirection:"column", justifyContent:"space-between", alignItems: "center", height: '100%', width: '100%'}} >
						<DataTable
							value={dialogData}
							scrollable
							stripedRows
							scrollHeight='70vh'
							selectionMode={'checkbox'}
							selection={selectedItems}
							onSelectionChange={(e) => setSelectedItems(e.value)}
							style={{width: "100%"}}
							loading={removeLoading}
							virtualScrollerOptions={{ itemSize: 50 }}
							size='small'
							rows={20}
						>
							<Column selectionMode="multiple" ></Column>
							<Column field='desc_prod_estab' header='Descrição' sortable ></Column>
							<Column field='qtde_estab' header='Qtd. Estabelecimentos' sortable ></Column>
							<Column field='qtde_empresas' header='Qtd. Empresas' sortable ></Column>
							<Column field='points' header='Chance de acerto' body={pointsColorTemplate} sortable ></Column>
						</DataTable>
						<Button label='Desassociar' style={{marginTop: '10px'}} onClick={() => removeData()} disabled={!removeEnable} loading={removeLoading} />
					</div>
				</Dialog>
			</div>
		</>
	)
}
